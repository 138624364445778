<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>角色列表</span>
      </div>
      <div class="right">
        <!-- <el-input v-model="keyword" placeholder="请输入搜索..."></el-input>
        <el-button type="primary" @click="getList">搜索</el-button> -->
        <el-button @click="add">添加</el-button>
      </div>
    </div>
    <el-table :data="list" row-key="id">
      <el-table-column
        prop="role_name"
        label="名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="role_status" label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.role_status ? "正常" : "禁用" }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建日期" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="goods_name" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button size="small" type="danger" @click="handleDel(row.role_id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
    <el-drawer title="添加角色/编辑角色" :visible.sync="showAdd" :before-close="handleClose">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
        <el-form-item label="角色名称：" prop="role_name">
          <el-input v-model="addForm.role_name"></el-input>
        </el-form-item>
        <el-form-item label="角色简介：">
          <el-input v-model="addForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="角色状态：">
          <el-radio-group v-model="addForm.role_status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分配权限：">
          <el-tree
            ref="tree"
            :data="treeList"
            :default-expanded-keys="defaultExpandedKeys"
            show-checkbox
            node-key="auth_id"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
        <el-form-item style="text-align:right;padding-right:20px;">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="comfirmAdd('form')"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-main>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
export default {
  data() {
    return {
      page: 1,
      rows: 10,
      list: [],
      total_number: 0,
      showAdd: !1,
      type: 0,
      treeList: [],
      defaultProps: {
        children: "childs",
        label: "auth_name",
      },
      addForm: {
        role_name: "",
        desc: "",
        role_auth: "",
        role_status: 1,
      },
      defaultExpandedKeys: [],
      rules: {
        role_name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();
    this.get_menu();
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      this.$axios.post(this.$api.Roles.getAll, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleDel(role_id) {
      this.$confirm("确认删除此角色？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(this.$api.Roles.delete, {
            role_id: role_id,
          })
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.$message.success("删除成功");
              this.list = this.list.filter((item) => item.role_id != role_id);
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    handleClose(){
      if(this.type == 0) this.$refs["form"].resetFields();
      this.showAdd = !1;
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    handleEdit(row) {
      let treeList = this.treeList;
      let selectTree = [];
      row = JSON.parse(JSON.stringify(row));
      this.type = 1;
      this.addForm.role_name = row.role_name;
      this.addForm.desc = row.desc;
      this.addForm.role_status = row.role_status;
      this.addForm.role_id = row.role_id;
      let tree_id = row.role_auth.split(",");
      for (let i in tree_id) tree_id[i] = Number(tree_id[i]);
      this.defaultExpandedKeys = tree_id;
      for (let i in treeList) {
        tree_id.map((id) => {
          if (id == treeList[i].auth_id) {
            let obj = {
              label: treeList[i].auth_name,
              auth_id: id,
            };
            selectTree.push(obj);
          }
        });
      }
      this.showAdd = !0;
      setTimeout(() => {
        this.$refs.tree.setCheckedNodes(selectTree);
      }, 50);
    },
    add() {
      this.showAdd = !0;
      this.type = 0;
      for (let i in this.addForm) this.addForm[i] = "";
      this.addForm.role_status = 1;
    },
    //获取角色菜单
    get_menu() {
      this.$axios.post(this.$api.get_menu).then((res) => {
        if (res.code == 0) {
          let list = res.result;
          this.treeList = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //确认添加
    comfirmAdd(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          let url = this.type ? this.$api.Roles.update : this.$api.Roles.add;
          let id = this.$refs.tree.getCheckedKeys().join(",");
          if (!id) return this.$message.warning("请选择分配权限");
          this.addForm.role_auth = id;
          if (this.type) {
            if (!this.addForm.u_password) delete this.addForm.u_password;
          }
          this.$axios.post(url, this.addForm).then((res) => {
            if (res.code == 0 || res.code == 200) {
              this.showAdd = !1;
              this.$message.success(this.type ? "编辑成功" : "添加成功");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      .el-input {
        width: 270px;
        margin-right: 20px;
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409eff;
      }
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
  .el-form-item {
    text-align: left;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .el-form{
    .el-input{
      width: 300px;
    }
  }
  /deep/ .el-drawer__container {
    text-align: left;
  }
}
</style>
